import * as React from 'react';
import {
    Button, Paper, Grid, Stack, Divider, Box, Typography, TextField,
    FormGroup, FormControlLabel, Switch, ToggleButtonGroup, ToggleButton, IconButton,
    Dialog,
    DialogTitle
} from '@mui/material';
import axios from "../../axios-config";
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

// OUR COMPS
import LeftPanelSkeleton from './LeftPanelSkeleton';
import RightPanelSkeleton from './RightPanelSkeleton';
import RepairOrderServicesPnael from './RepairOrderServicesPanel';
import RepairOrderGeneralPanel from './RepairOrderGeneralPanel';
import { useTheme } from '@mui/material/styles';
import './service-table.css';

import ToolBarHelper from '../../components/ToolBarHelper/ToolBarHelper';
import PrintComponent from '../../components/Printing/PrintComponent';


//ICONS
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import ReminderPopover from '../../components/Popovers/ReminderPopover';
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { customerToInvoiceData, organizationToInvoiceData, servicesToItems } from '../../utils/InvoiceHelpers';


import { useReactToPrint } from "react-to-print";

import ReactToPrint from "react-to-print";
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import ConsoleHelper from '../../utils/ConsoleHelper';
import RepairOrderLogPopover from './RepairOrderLogPopover';
import { processRepairOrder } from '../../utils/repair-order-helpers';
import { formatCurrency } from '../../utils/currency';
import QuoteCreatorDialog from './Dialogs/QuoteCreatorDialog';
import { RequestQuoteOutlined } from '@mui/icons-material';



export const RepairOrderContext = React.createContext(null);
export const ServicesContext = React.createContext(null);
export const OutsourceServicesContext = React.createContext(null);

export const PaymentsContext = React.createContext(null);


const EditOrderPage = ({ changeColor }) => {
    const [settings, setSettings] = useState(null);

    const { t } = useTranslation();

    const componentRef = React.useRef();
    const onBeforeGetContentResolve = React.useRef(null);

    const [printLoading, setPrintLoading] = useState(false);
    const [organization, setOrganization] = useState({});


    const [repairOrder, setRepairOrder] = useState({});
    const [services, setServices] = useState(null);
    const [repairPayments, setRepairPayments] = useState([]);
    const [repairOrderParts, setRepairOrderParts] = useState(null);

    const [outsourceServices, setOutsourceServices] = useState(null)
    const [comments, setComments] = useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isCopied, setIsCopied] = useState(false);
    const [previewPrint, setPreviewPrint] = useState(true);
    const [estimateDialogOpen, setEstimateDialogOpen] = useState(false);


    const currency = useSelector((state) => state.auth.currency);

    const handleDialogClose = () => {
        setPreviewPrint(false);
    }

    const handleReminderOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const reminderPopoverOpen = Boolean(anchorEl);

    const handleReminderClose = () => {
        setAnchorEl(null);
    };
    const [loading, setLoading] = useState(true);
    let { entryId } = useParams();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    let navigate = useNavigate();
    const theme = useTheme();

    const [repairLogAnchor, setRepairLogAnchor] = React.useState(null);
    const closeRepairLogs = () => {
        setRepairLogAnchor(null);
    }
    const openRepairLogsPopover = async (event) => {
        setRepairLogAnchor(event.currentTarget);
    }


    useEffect(async () => {
        try {
            const endpoint = `/repair-orders/${entryId}`;
            const response = await axios.get(endpoint);
            if (response.data.repairOrder) {
                ConsoleHelper(response.data.repairOrder);

                setRepairOrder(processRepairOrder(response.data.repairOrder))
                setServices(response.data.repairOrder.services);
                setRepairPayments(response.data.repairOrder.repairPayments);
                setRepairOrderParts(response.data.repairOrder.repairOrderParts)
                if (response.data.repairOrder?.outsourcedTo) {
                    setOutsourceServices(response.data.repairOrder.outsourcedTo.services)
                }
                let orderComments = [];
                // Our comments
                if (response.data.repairOrder?.comments) {
                    orderComments = [...orderComments, ...response.data.repairOrder?.comments]
                }
                // From partner
                if (response.data.repairOrder?.outsourcedFrom?.comments) {

                    orderComments = [...orderComments, ...response.data.repairOrder?.outsourcedFrom?.comments]

                }
                // To partner
                if (response.data.repairOrder?.outsourcedTo?.comments) {

                    orderComments = [...orderComments, ...response.data.repairOrder?.outsourcedTo?.comments]

                }
                setComments(orderComments);
                setLoading(false);
                const brand = response.data.repairOrder.brand;
                const model = response.data.repairOrder.model;
                const input = brand + " " + model;

                // getNameSuggestion(input);
            }
        }
        catch (err) {
            console.log(err);
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        };
    }, [entryId]);

    // useEffect(() => {
    //     console.log("Change color")
    //     changeColor('white')
    // }, [])

    const getNameSuggestion = async (input) => {
        try {
            const response = await axios.post(`/ai/suggest-device-name`, { input: input });
            if (response.status === 200) {
                //check if any match has a ratio > 85
                console.log(response.data.match);
                if (response.data.match.length > 0) {
                    const match = response.data.match.find(item => item.ratio > 85);
                    if (match) {
                        console.log(`Create suggestion: ${match.device.brand} ${match.device.model} `, match.ratio);
                        console.log(match);
                    }
                }else{
                    console.log(`exact match`);
                }

            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleCopy = () => {

        let clipboardContent = "";
        const ref = repairOrder.outsourcedFrom ? repairOrder.outsourcedFrom.organization.id + "#" + repairOrder.outsourcedFrom.organizationCounter :
            repairOrder.organizationId + "#" + repairOrder.organizationCounter
        clipboardContent += `Ref: ${ref} \n`
        clipboardContent += `${t('Device')}: ${repairOrder.brand} ${repairOrder.model} \n`;
        clipboardContent += `${t('Malfunction')}: ${repairOrder.malfunction} \n`;
        if (services) {
            clipboardContent += `${t('Services')}: [\n`;
            services.map(service => {
                // clipboardContent += `${service.name}:{\n`;
                // clipboardContent += `Price: ${currency}${(service.unitPrice * service.quantity - service.discount).toFixed(2)}\n`;
                // clipboardContent += `Accepted: ${service.isAccepted}\n`;
                // clipboardContent += `},\n`;
                // const accepted = service.accepted ? "Is Accepted" : "Not Accepted";
                const price = `${formatCurrency(service.unitPrice * service.quantity - service.discount, currency)}`;
                clipboardContent += `         ${service.name}, ${price} \n`
            })
            clipboardContent += `]`;
        }

        navigator.clipboard.writeText(clipboardContent);

        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
    };

    const handleOnBeforeGetContent = () => {
        return new Promise(async (resolve, reject) => {
            const paymentsResponse = await axios.get(`/repair-orders/${repairOrder.id}/payments`);
            if (paymentsResponse.status === 200) {
                setRepairPayments(paymentsResponse.data);
            }
            const response = await axios.get(`/organizations`);
            // console.log(response);
            const successfuly = response.status === 200 || response.status === 201;
            if (successfuly) {
                setOrganization(response.data);
            }
            setPrintLoading(false);
            resolve();
        });
    }


    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "Ticket",
        onBeforeGetContent: handleOnBeforeGetContent,
        // onBeforePrint: handleBeforePrint,
        // onAfterPrint: handleAfterPrint,
        // removeAfterPrint: true
    });

    const handleQuickInvoice = async () => {
        try {
            // get services for this order
            if (!repairOrder.customer) {
                enqueueSnackbar("No customer selected.", { variant: "error" });
                return
            }
            const servicesResponse = await axios.get(`/repair-orders/${repairOrder.id}/services`);


            if (servicesResponse.status !== 200) {
                const error = new Error();
                throw error;
            }
            const services = servicesResponse.data.services.filter(service => service.isAccepted);
            if (services.length <= 0) {
                enqueueSnackbar("Unable to invoice. There are no accepted services.", { variant: "error" });
                return;
            }
            const lastInvoiceResponse = await axios.get(`invoices/last`);
            if (lastInvoiceResponse.status !== 200) {
                const error = new Error();
                throw error;
            }
            let refId = 1;
            if (lastInvoiceResponse.data?.refId) {
                refId = lastInvoiceResponse.data.refId + 1;
            }
            const organizationResponse = await axios.get(`/organizations`);
            if (organizationResponse.status !== 200) {
                const error = new Error();
                throw error;
            }
            let billingTo = null;
            if (repairOrder.customer.name && repairOrder.customer.taxIdentifier) {
                billingTo = customerToInvoiceData(repairOrder.customer);
            }

            let orgData = organizationResponse.data;
            let billingFrom = organizationToInvoiceData(orgData, billingTo === null ? true : false);

            if (billingFrom.length < 1) {
                const error = new Error();
                throw error;
            }
            const items = servicesToItems(repairOrder, repairOrder.services.filter(service => service.isAccepted));
            if (!items) {
                return;
            }
            let dayjs = require('dayjs')
            const now = dayjs();
            const invoiceData = {
                refId: refId,
                fileId: lastInvoiceResponse.data?.fileId,
                billingFrom: billingFrom,
                billingTo: billingTo,
                invoiceTo: 'customer',
                customerSelect: repairOrder.customer,
                partnersSelect: null,
                paymentInfo: null,
                terms: null,
                items: items,
                date: now.toISOString(),
                taxIncluded: true,
                taxAmount: 21
            }

            const newInvoiceResp = await axios.post(`/invoices/customer`, invoiceData);

            if (newInvoiceResp?.status === 201) {
                enqueueSnackbar(`Invoice created succesfully!`, { variant: "success" });
                // setRefId(parseInt(newInvoiceResp.data.refId) + 1);
                // setItems([]);
                // setCheckedOrders([]);

                // // To force a refetch on the side menu
                // const currentPartnerSelect = partnersSelect;
                // setPartnersSelect(0);
                // setPartnersSelect(currentPartnerSelect);
                // const currentCustomerSelect = customerSelect;
                // setCustomerSelect(null);
                // setCustomerSelect(currentCustomerSelect);
            }
        } catch (err) {
            if (err.response.data.message) {
                enqueueSnackbar(err.response.data.message, { variant: "error" });
            }
            else {
                enqueueSnackbar("Something went wrong!", { variant: "error" });
            }

        }
    };

    const handleCreateQuote = async () => {
        try {
            setEstimateDialogOpen(true);
        }
        catch (err) {


        }
    }

    return (
        // <RepairOrderContext.Provider value={{ state: repairOrder, update: setRepairOrder }}>
        //     <ServicesContext.Provider value={services}>
        <>
            <Box className={'container mainContainer docsContainer '} >
                <ToolBarHelper>
                    <Stack direction={'row'} gap={1} flexWrap={'wrap'} alignItems={'flex-start'} width={"100%"}>
                        <Button color={'black'} variant='outlined' startIcon={<ArrowBackIcon />} onClick={() => navigate("/")}>{t('Back')}</Button>
                        <Button color={'black'} variant='outlined' startIcon={<NotificationAddOutlinedIcon />} onClick={handleReminderOpen}>{t('New reminder')}</Button>
                        <Button color={'black'} variant='outlined' startIcon={<LocalPrintshopOutlinedIcon />} onClick={handlePrint}>{t('Print ticket')}</Button>
                        {repairOrder.customer && <Button color={'black'} variant='outlined' startIcon={<RequestQuoteOutlined />} onClick={handleCreateQuote}>{t('Create quote')}</Button>}
                        {/* <ButtonGroup disableElevation variant={'text'} color={'secondary'} >
                </ButtonGroup> */}
                        <Button
                            color={'black'} onClick={handleCopy}
                            variant='outlined'
                            sx={{ color: isCopied ? "blue.main" : "secondary" }}
                            startIcon={isCopied ? <DoneAllIcon /> : <ContentCopyIcon />}
                        >
                            {isCopied ? t("Copied to clipboard") : t("Copy order data")}
                        </Button>
                        {repairOrder.outsourcedFrom ? null : <Button disabled={!repairOrder.customer} color={'black'} variant='outlined' onClick={handleQuickInvoice} startIcon={<PostAddOutlinedIcon />}>{t('Quick invoice')}</Button>}
                        {/* <Button color={'secondary'} startIcon={<NotificationsActiveIcon />} onClick={() => navigate("/")}>Add to invoice creator</Button> */}
                        <Button

                            color={'black'}
                            onClick={openRepairLogsPopover}
                            variant='outlined'
                            startIcon={<ManageHistoryOutlinedIcon />}
                        >
                            {t("View logs")}
                        </Button>
                        <Box sx={{ flexGrow: 1 }} />
                        {/* <IconButton color={'black'}   onClick={handlePrint}>{<MoreHorizIcon/>}</IconButton> */}

                    </Stack>
                    <ReminderPopover open={reminderPopoverOpen} anchorEl={anchorEl} handleClose={handleReminderClose} repairOrderId={repairOrder.id} organizationCounter={repairOrder.organizationCounter} />
                </ToolBarHelper>
                <RepairOrderLogPopover closeRepairLogs={closeRepairLogs} anchor={repairLogAnchor} organizationCounter={repairOrder.organizationCounter} />

                <div className="content" >
                    <div style={{ display: 'none' }}>
                        {printLoading ? null :
                            <PrintComponent value={0} ref={componentRef} settings={settings} repairOrder={repairOrder} organization={organization} repairPayments={repairPayments} />
                        }
                    </div>
                    <Box className='splitLeft' borderRight={'1px solid'} borderColor={'lightGrey.main'} backgroundColor={'white'}>
                        {loading ? <LeftPanelSkeleton /> :
                            <RepairOrderServicesPnael
                                repairOrder={repairOrder}
                                services={services}
                                setServices={setServices}
                                repairOrderParts={repairOrderParts}
                                setRepairOrderParts={setRepairOrderParts}
                                repairPayments={repairPayments}
                                setRepairPayments={setRepairPayments}
                                outsourceServices={outsourceServices}
                                setOutsourceServices={setOutsourceServices}
                                setRepairOrder={setRepairOrder} />
                        }
                    </Box>
                    {/* display: flex;
flex: 1 1 auto;
flex-direction: column;
min-height: 1px; */}

                    <Box className='splitRight' id={'window'} display={'flex'} flexDirection={'column'} minHeight={'1px'} sx={{ overflowY: 'hidden' }}>
                        {loading ? <RightPanelSkeleton /> :
                            <RepairOrderGeneralPanel repairOrder={repairOrder} setRepairOrder={setRepairOrder}
                                comments={comments}
                                setComments={setComments} />
                        }
                    </Box>
                </div >
            </Box >
            <QuoteCreatorDialog open={estimateDialogOpen} onClose={() => setEstimateDialogOpen(false)} repairOrder={repairOrder} services={services} />

        </>
        //     </ServicesContext.Provider>
        // </RepairOrderContext.Provider>

    )
}

export default EditOrderPage;