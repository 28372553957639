import * as React from 'react';
import axios from "../../axios-config";
import {
    Box, Tabs, Tab, Button, Divider,
    Badge, TextField, Fab, Stack,
    IconButton
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useParams, useNavigate } from "react-router-dom";
import RepairOrderLogPopover from './RepairOrderLogPopover';
import OrderEditGeneral from './OrderEditGeneral';
import EditClientDialog from './Dialogs/EditCustomerDialog';
import Comments from './Comments/Comments';
import { useTranslation } from 'react-i18next';

import SendIcon from '@mui/icons-material/Send';
import { AttachFile, ImageOutlined } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import NewFeatureTooltip from 'components/NewFeatureTooltip/NewFeatureTooltip';


const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
    color: "black",
});

const RepairOrderGeneralPanel = ({ repairOrder, setRepairOrder, comments, setComments }) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();


    const [inputValue, setInputValue] = useState("");
    const [isPublic, setIsPublic] = useState('private');

    const [editClient, setEditClient] = useState(false);

    const handleComment = async (event) => {
        event.preventDefault();
        const commentBody = inputValue.trim();
        if (commentBody.length == 0) return;
        try {
            if (inputValue !== "") {
                setInputValue("");
            }
            const isPublicBool = isPublic === 'public';

            const response = await axios.post(`/repair-orders/${repairOrder.id}/comments`, { body: inputValue, isPublic: isPublicBool });
            if (response.status === 200 || response.status === 201) {
                console.log(response.data);
                setComments(response.data);
            }
        }
        catch (err) {
            console.log(err);
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        };
    };

    const hanldeNewCustomerData = (newCustomer) => {
        setRepairOrder(prevState => {
            return { ...prevState, customer: newCustomer }
        })
    }


    {/* <Comments orderId={repairOrder.id} orderIsShared={repairOrder.outsourcedFromId != null || repairOrder.outsourcedToId != null} /> */ }

    return (<Box id={'article'} display={'flex'} flex={'1 1 auto'} flexDirection={'column'} minHeight={'1px'} >
        <Box display={'flex'} flex={'1 1 auto'} flexDirection={'column'} minHeight={'1px'} sx={{ overflowY: 'auto' }} backgroundColor={'lightBlue.main'}>
            <Box id='panel-content' flex={'1 1 auto'}>
                <Box flex={'1 1 auto'} >
                    <OrderEditGeneral
                        repairOrder={repairOrder}
                        setRepairOrder={setRepairOrder}
                        editClient={editClient}
                        setEditClient={setEditClient}
                    />
                </Box>
                <Box flex={'1 1 auto'}  >
                    <Comments order={repairOrder} isPublic={isPublic} comments={comments} setComments={setComments} setIsPublic={setIsPublic}
                        orderIsShared={repairOrder.outsourcedFromId != null || repairOrder.outsourcedToId != null} />
                    {repairOrder.outsourcedFromId == null ?
                        <EditClientDialog dialogOpen={editClient} item={repairOrder.customer} handleDialogClose={() => setEditClient(false)} onEdit={hanldeNewCustomerData} />
                        : null
                    }
                </Box>
            </Box>
        </Box>
        <Box sx={{ flexShrink: '0', bottom: '0', width: '100%', zIndex: 100, backgroundColor: 'white', borderTop: '1px darkGrey solid', py: '20px', px: { xs: '10px', sm: '15px' } }}>
            <form >

                <Stack sx={{
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    padding: '8px',
                    width: '100%',
                    backgroundColor: theme.palette.lightBlue.main
                }}>

                    <Stack
                        direction={'row'}
                        spacing={2}
                        alignItems="flex-end"
                    >
                        {/* <NewFeatureTooltip
                            hintName="attach-image-to-comment"
                            title={t("newFeatureTooltip.attachImageToComment.title")}
                            description={t("newFeatureTooltip.attachImageToComment.description")}
                            badge={t("newFeatureTooltip.newBadge")}
                        >
                            <IconButton
                                component="label"
                                role={undefined}
                                // variant="contained"
                                tabIndex={-1}
                                size="small"
                            >
                                <ImageOutlined fontSize="small" />
                                <VisuallyHiddenInput
                                    type="file"
                                    // onChange={handleFileUpload}
                                    multiple={true}
                                />
                            </IconButton>
                        </NewFeatureTooltip> */}
                        {/* Image/Attachment area */}

                        {/* Text input area */}
                        <TextField
                            value={inputValue}
                            onChange={(e) => {
                                setInputValue(e.target.value);
                            }}
                            autoComplete="off"
                            multiline
                            variant="standard"
                            placeholder={t("Leave a comment here...")}
                            fullWidth
                            InputProps={{
                                disableUnderline: true, // Removes the underline
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    handleComment(e);
                                }
                            }}
                            sx={{
                                '& .MuiInputBase-root': {
                                    padding: 0,
                                    paddingLeft: '8px',
                                    paddingBottom: '4px',
                                    color: theme.palette.black.main
                                },
                            }}
                        />

                        <IconButton
                            color="primary"
                            aria-label="add"
                            size="small"
                            onClick={handleComment}
                            sx={{
                                boxShadow: "none",
                                backgroundColor: "primary.main",
                                color: "white",
                                '&:hover': {
                                    backgroundColor: "primary.dark",
                                }
                            }}
                            variant="contained"
                        >
                            <SendIcon />
                        </IconButton>
                    </Stack>
                </Stack>
            </form>
        </Box>
    </Box>
    )

}

export default React.memo(RepairOrderGeneralPanel);